import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { reducer as firebase } from 'react-redux-firebase'
import { firestoreReducer as firestore } from 'redux-firestore'
import { reducer as form } from 'redux-form'
import { reducer as notifications } from 'modules/notification'
import { reducer as toastrReducer } from 'react-redux-toastr';
import { CookieStorage } from 'redux-persist-cookie-storage';
import Cookies from 'cookies-js';

import { authReducer } from './auth';
import { usersReducer } from './users';
import { preferencesReducer } from './preferences';
import { locationReducer } from './location'


export function makeRootReducer(asyncReducers) {
  return combineReducers({
    auth: persistReducer(
      {
        key: 'auth',
        storage,
        blacklist: ['error', 'loading']
      },
      authReducer
    ),
    preferences: persistReducer(
      { key: 'preferences', storage: new CookieStorage(Cookies) },
      preferencesReducer
    ),
    users: persistReducer(
      {
        key: 'users',
        storage,
        blacklist: ['error', 'loading']
      },
      usersReducer
    ),
    toastr: toastrReducer,
    firebase,
    firestore,
    form,
    notifications,
    location: locationReducer,
    ...asyncReducers
  })
}

export function injectReducer(store, { key, reducer }) {
  store.asyncReducers[key] = reducer // eslint-disable-line no-param-reassign
  store.replaceReducer(makeRootReducer(store.asyncReducers))
}

export default makeRootReducer
