import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useSelector, shallowEqual } from 'react-redux'
import PropTypes from 'prop-types'

import Layout from 'components/Layout'
import { paths } from 'Router'

const PrivateRoute = (props) => {
  const { path, component: Component, exact, permissions, componentProps } = props
  const { id } = useSelector(
    state => ({
      id: state.auth.userData.id
    }),
    shallowEqual
  );

  return (
    <Layout>
      <Route
        exact={ exact }
        path={ path }
        render={ () => ((!permissions || id) ?
          <Component path={ path } { ...componentProps }/>
          : <Redirect to={ paths.LOGIN } />) }
      />
    </Layout>
  );
};

PrivateRoute.propType = {
  path: PropTypes.string.isRequired,
  component: PropTypes.element.isRequired
};

export default PrivateRoute;
