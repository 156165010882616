import React from 'react'
import { Route } from 'react-router-dom'
import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect'
import locationHelperBuilder from 'redux-auth-wrapper/history4/locationHelper'
import { createBrowserHistory } from 'history'
import { Loadable } from 'utils/components'
import LoadingSpinner from 'components/LoadingSpinner'

const locationHelper = locationHelperBuilder({})
const history = createBrowserHistory()

const AUTHED_REDIRECT = 'AUTHED_REDIRECT'
const UNAUTHED_REDIRECT = 'UNAUTHED_REDIRECT'


export const paths = {
  ROOT: '/',
  LOGIN: '/login',
  USERS: '/users',
  ADD_USER: '/users/new',
  MODIFY_USER: '/users/:id',
  PROFILE: '/profile',
  RESET_PASSWORD: '/recover-password',
  SECTION: '/section',
  SUBMENU_1: '/submenu1',
  SUBMENU_2: '/submenu2',
  PROJECTS: '/projects',
  TODOS: '/todos',
  ARTICLES: '/articles'
}

const schema = {
  form:{
    "title": "Create A New Article",
    "description": "",
    "type": "object",
    "required": [
      "title"
    ],
    "properties": {
      "title": {
        "type": "string",
        "title": "Title"
      },
      "Headline": {
        "type": "string",
        "title": "Headline"
      },
      "other": {
        "type": "string",
        "title": "other",
        "minLength": 10
      }
    }
  },
  ui: {
    "Title": {
      "ui:autofocus": true,
      "ui:emptyValue": "",
      "ui:autocomplete": "family-name"
    },
    "Headline": {
      "ui:emptyValue": "",
      "ui:autocomplete": "given-name"
    },
    "age": {
      "ui:widget": "updown",
      "ui:title": "Age of person",
      "ui:description": "(earthian year)"
    },
    "bio": {
      "ui:widget": "textarea"
    },
    "password": {
      "ui:widget": "password",
      "ui:help": "Hint: Make it strong!"
    },
    "date": {
      "ui:widget": "alt-datetime"
    },
    "telephone": {
      "ui:options": {
        "inputType": "tel"
      }
    },
    "id": {
      "ui:emptyValue": ""
    }
  },
  data: {

  }
}

const routes = [
  {
    label: 'Home',
    path: paths.ROOT,
    permissions: true,
    exact: true,
    component: Loadable({
      loader: () => import('pages/Home')
    })
  },
  {
    label: 'Login',
    path: paths.LOGIN,
    permissions: false,
    exact: true,
    component: Loadable({
      loader: () => import('pages/Login')
    })
  },
  {
    label: 'Users',
    path: paths.USERS,
    permissions: true,
    exact: true,
    component: Loadable({
      loader: () => import('pages/Users')
    })
  },
  {
    label: 'New User',
    path: paths.ADD_USER,
    permissions: true,
    exact: true,
    component: Loadable({
      loader: () => import('pages/Users/routes/User')
    })
  },
  {
    label: "Modify User",
    path: paths.MODIFY_USER,
    permissions: true,
    exact: true,
    component: Loadable({
      loader: () => import('pages/Users/routes/User')
    })
  },
  {
    label: "User Profile",
    path: paths.PROFILE,
    permissions: true,
    exact: true,
    component: Loadable({
      loader: () => import('pages/Profile')
    })
  },
  {
    label: "Recover Password",
    path: paths.RESET_PASSWORD,
    permissions: false,
    exact: true,
    component: Loadable({
      loader: () => import('pages/ResetPassword')
    })
  },
  {
    label: "Section",
    path: paths.SECTION,
    permissions: true,
    exact: true,
    component: Loadable({
      loader: () => import('pages/Section')
    })
  },
  {
    label: "Submenu 1",
    path: paths.SUBMENU_1,
    permissions: true,
    exact: true,
    component: Loadable({
      loader: () => import('pages/Submenu')
    })
  },
  {
    label: "Submenu 2",
    path: paths.SUBMENU_2,
    permissions: true,
    exact: true,
    component: Loadable({
      loader: () => import('pages/Submenu')
    })
  },
  {
    label: 'Todos',
    path: paths.TODOS,
    permissions: true,
    exact: false,
    component: Loadable({
      loader: () => import('pages/Todos')
    })
  },
  {
    label: 'Articles',
    path: "/articles",
    permissions: true,
    exact: true,
    component: Loadable({
      loader: () => import('pages/Collection')
    }),
    componentProps: {
      schema: schema,
      collectionPath: 'articles',
      title: "Articles"
    }
  }
]

/**
 * Higher Order Component that redirects to `/login` instead
 * rendering if user is not authenticated (default of redux-auth-wrapper).
 * @param {Component} componentToWrap - Component to wrap
 * @return {Component} wrappedComponent
 */
export const UserIsAuthenticated = connectedRouterRedirect({
  redirectPath: '/login',
  AuthenticatingComponent: LoadingSpinner,
  wrapperDisplayName: 'UserIsAuthenticated',
  // Want to redirect the user when they are done loading and authenticated
  authenticatedSelector: ({ firebase: { auth } }) =>
    !auth.isEmpty && !!auth.uid,
  authenticatingSelector: ({ firebase: { auth, isInitializing } }) =>
    !auth.isLoaded || isInitializing,
  redirectAction: newLoc => dispatch => {
    // Use push, replace, and go to navigate around.
    history.push(newLoc)
    dispatch({
      type: UNAUTHED_REDIRECT,
      payload: { message: 'User is not authenticated.' }
    })
  }
})

/**
 * Higher Order Component that redirects to listings page or most
 * recent route instead rendering if user is not authenticated. This is useful
 * routes that should not be displayed if a user is logged in, such as the
 * login route.
 * @param {Component} componentToWrap - Component to wrap
 * @return {Component} wrappedComponent
 */
export const UserIsNotAuthenticated = connectedRouterRedirect({
  AuthenticatingComponent: LoadingSpinner,
  wrapperDisplayName: 'UserIsNotAuthenticated',
  allowRedirectBack: false,
  // Want to redirect the user when they are done loading and authenticated
  authenticatedSelector: ({ firebase: { auth } }) => auth.isEmpty,
  authenticatingSelector: ({ firebase: { auth, isInitializing } }) =>
    !auth.isLoaded || isInitializing,
  redirectPath: (state, ownProps) =>
    locationHelper.getRedirectQueryParam(ownProps) || paths.LOGIN,
  redirectAction: newLoc => dispatch => {
    // Use push, replace, and go to navigate around.
    history.push(newLoc)
    dispatch({
      type: AUTHED_REDIRECT,
      payload: { message: 'User is not authenticated.' }
    })
  }
})

/**
 * Render children based on route config objects
 * @param {Array} routes - Routes settings array
 * @param {Object} match - Routes settings array
 * @param {Object} parentProps - Props to pass to children from parent
 */
export function renderChildRoutes(routes, match, parentProps) {
  return routes.map(route => (
    <Route
      key={`${match.url}-${route.path}`}
      path={`${match.url}/${route.path}`}
      render={props => <route.component {...parentProps} {...props} />}
    />
  ))
}

export default function createRoutes(store) {
  return routes
}
