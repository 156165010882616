import { createIntl, createIntlCache } from 'react-intl';
import { getIntlContext } from 'constants/locale'
export * from 'constants/locale'


export const validateEmail = email => {
  return email.match(
    // eslint-disable-next-line no-useless-escape
    /^[-a-z0-9~!$%^&*_=+}{\'?]+(\.[-a-z0-9~!$%^&*_=+}{\'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|mobi|[a-z][a-z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/i
  );
};

export const inputValidations = (email, password, locale) => {
  let inputs = {
    email: {
      modifier: null,
      message: null
    },
    password: {
      modifier: null,
      message: null
    },
    canSubmit: null
  };
  const intl = getIntlContext(locale);

  const setInputs = (key, value) => {
    inputs = { ...inputs, [`${key}`]: value };
  };

  const isValidEmail = email && validateEmail(email);

  if (email && !isValidEmail) {
    setInputs('email', {
      modifier: 'is-danger',
      message: intl.formatMessage({ id: 'utils.invalidEmail' })
    });
  }

  const isValidPassword = password && password.length >= 6;

  if (isValidPassword) {
    setInputs('password', {
      modifier: 'is-success',
      message: intl.formatMessage({ id: 'utils.safePassword' })
    });
  } else if (password) {
    setInputs('password', {
      modifier: 'is-danger',
      message: intl.formatMessage({ id: 'utils.unsafePassword' })
    });
  }

  if (isValidEmail && isValidPassword) {
    setInputs('canSubmit', true);
  }

  return inputs;
};



/**
 * Log a message and return data passed. Useful for logging
 * messages within functional programming flows.
 * @param message - Message to log along with data.
 * @example Basic
 * import { flow, map as fpMap } from 'lodash'
 * const original = []
 * flow(
 *   fpLog('Before Map'),
 *   fpMap('name')
 *   fpLog('After Map'),
 * )(original)
 * // => 'Before Map' [{ name: 'test' }]
 * // => 'After Map' ['test']
 */
export function fpLog(message) {
  return existing => {
    console.log(message, existing) // eslint-disable-line no-console
    return existing
  }
}

/**
 * Initialize global scripts including analytics and error handling
 */
export function initScripts() {
  // Initialize global scripts here
}
