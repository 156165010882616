import React from 'react';
import { Route, Switch, BrowserRouter } from 'react-router-dom';
import NotFound from 'pages/NotFound';
import PrivateRoute from '../PrivateRoute';

const Router = (props) => {
  const { routes } = props;
  return (
    <BrowserRouter>
      <Switch>
        {
        routes.map((settings, index) => (
            <PrivateRoute key={`Route-${index}`} {...settings}  />
        ))}
        <Route component={NotFound} />
      </Switch>
    </BrowserRouter>
  );
};

export default Router;
