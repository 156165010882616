// TODO Break up into modules for each firebase app
import React from 'react'
import firebase from 'firebase/app'
import 'firebase/database'
import 'firebase/auth'
import 'firebase/storage'
import 'firebase/functions'
import 'firebase/firestore'
import { ReactReduxFirebaseProvider } from 'react-redux-firebase'
import { createFirestoreInstance } from 'redux-firestore'
import { getIntlContext, messages } from 'constants/locale'

import config from './init'


firebase.initializeApp(config)
firebase.database()
firebase.storage()
firebase.firestore()

export const firebaseError = (error, locale) => {
  const intl = getIntlContext(locale);
  return intl.formatMessage({
    id: error,
    defaultMessage: messages[locale]['utils.default']
  });
};

export default firebase;
