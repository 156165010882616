
export const env = process.env.NODE_ENV

export const reduxFirebase = {
  enableLogging: true,
  userProfile: 'users',
  useFirestoreForProfile: true,
  useFirestoreForStorageMeta: true
}

export default {
  env,
  reduxFirebase
}
