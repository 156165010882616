import React from 'react'
import ReactDOM from 'react-dom'
import { initScripts } from 'utils'
import createStore from 'state/store'
import createRoutes from 'Router/routes'
import { version } from '../package.json'
import { env } from 'config'
import App from 'App'

import './assets/css/main.css';
import './assets/css/index.css';

import * as serviceWorker from './serviceWorker'

// Window Variables
// ------------------------------------
window.version = version
window.env = env
initScripts()

// Store Initialization
// ------------------------------------
const initialState = window.___INITIAL_STATE__ || {
  firebase: { authError: null }
}
const { store, persistor } = createStore(initialState)
const routes = createRoutes(store)

ReactDOM.render(
  <App
    store={ store }
    persistor={ persistor }
    routes={ routes }
  />,
  document.getElementById('root')
)

serviceWorker.unregister()
